import React, { useState, useEffect } from 'react'

// Componentes
import Layout from '../components/Layout';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';

const Contacto = () => {
  // Estados
  const [lang, setLang] = useState()
  const [today, setToday] = useState('2022-03-01')

  // Constantes
  const txt = {
    en: {
      pageTitle: 'Contact | HB Privileged',
      formTitle: 'Contact us and book your tour with us!',
      formName: 'Name',
      formEmail: 'Email',
      formPhone: 'Phone',
      formCountry: 'Country',
      formCity: 'City',
      formZip: 'Zip Code',
      formTour: 'Choose the tour you are interested or type it if you cannot find it',
      formDate: 'Select Date',
      formNoPer: 'No. of person',
      formMsg: 'Message (optional)',
      formBtn: 'Send',
      formTit: 'A valid telephone number consist of a 3 digits code area between brackets, a space, the three first digits of the number, a space or hypen (-), and four more digits',
      stitle: 'We received your information.',
      stext: 'Thank you very much! We will be in touch soon.',
      tour1:'AKALI',
      tour2:'E BIKE & TACO TOUR',
      tour3:'CANOPY RIVER ZIP LINE',
      tour4:'SWIM WITH DOLPHINS AT AQUAVENTURAS',
      tour5:'BAHÍA ALEGRE MARIETAS',
      tour6:'MARIGALANTE PIRATE SHIP',
      tour7:'RHYTHMS OF THE NIGHT',
      tour8:'SEA, SUN, LAND TEQUILA & HIKING TOUR',
      tour9:'WHALES WATCHING',
      tour10:'HORSE BACK RIDING',
      tour11:'FISHING',
      tour12:'TEATRO VALLARTA MAYAHUEL',
      tour13:'JET SKIES TB TOURS',
      tour14:'UNIQUE ATVs & RZRs',
      tour15:'HIDDEN BEACH AT MARIETAS ISLANDS',
      tour16:'COOKING CLASSES',
      tour17:'DIVING',
      country1:'Germany',
      country2:'Argentina',
      country3	:	'Australia'	,
      country4	:	'Austria'	,
      country5	:	'Belgium'	,
      country6	:	'Belize'	,
      country7	:	'Bolivia'	,
      country8	:	'Brazil'	,
      country9	:	'Bulgaria'	,
      country10	:	'Canada'	,
      country11	:	'Chile'	,
      country12	:	'China'	,
      country13	:	'Colombia'	,
      country14	:	'Costa Rica'	,
      country15	:	'Croatia'	,
      country16	:	'Cuba'	,
      country17	:	'Denmark'	,
      country18	:	'Ecuador'	,
      country19	:	'Egypt'	,
      country20	:	'El Salvador'	,
      country21	:	'Slovenia'	,
      country22	:	'Spain'	,
      country23	:	'United States'	,
      country24	:	'Philippines'	,
      country25	:	'Finland'	,
      country26	:	'France'	,
      country27	:	'Ghana'	,
      country28	:	'Grenada'	,
      country29	:	'Greece'	,
      country30	:	'Guatemala'	,
      country31	:	'Haiti'	,
      country32	:	'Honduras'	,
      country33	:	'Hungary'	,
      country34	:	'India'	,
      country35	:	'Indonesia'	,
      country36	:	'Italy'	,
      country37	:	'Jamaica'	,
      country38	:	'Japan'	,
      country39	:	'Malaysia'	,
      country40	:	'Morocco'	,
      country41	:	'Mexico'	,
      country42	:	'Nicaragua'	,
      country43	:	'Nigeria'	,
      country44	:	'Norway'	,
      country45	:	'Netherlands'	,
      country46	:	'Pakistan'	,
      country47	:	'Panama'	,
      country48	:	'Paraguay'	,
      country49	:	'Peru'	,
      country50	:	'Poland'	,
      country51	:	'Portugal'	,
      country52	:	'United Kingdom'	,
      country53	:	'Czech Rep.'	,
      country54	:	'Rep. of Korea'	,
      country55	:	'Dominican Republic'	,
      country56	:	'Slovak Republic'	,
      country57	:	'Romania'	,
      country58	:	'Russia'	,
      country59	:	'Senegal'	,
      country60	:	'Serbia'	,
      country61	:	'South Africa'	,
      country62	:	'Sweden'	,
      country63	:	'Switzerland'	,
      country64	:	'Trinidad and Tobago'	,
      country65	:	'Tunisia'	,
      country66	:	'Turkey'	,
      country67	:	'Ukraine'	,
      country68	:	'Uruguay'	,
      country69	:	'Venezuela',
    },
    es: {
      pageTitle: 'Contacto | HB Privileged',
      formTitle: '¡Contáctanos y reserva tu tour con nosotros!',
      formName: 'Nombre',
      formEmail: 'E-mail',
      formPhone: 'Teléfono',
      formCountry: 'País',
      formCity: 'Ciudad',
      formZip: 'Código Postal',
      formTour: 'Escoge el tour que te interesa o escríbelo si no lo encuentras',
      formDate: 'Selecciona el día',
      formNoPer: 'No. de personas',
      formMsg: 'Mensaje (opcional)',
      formBtn: 'Enviar',
      formTit: 'Un número de teléfono válido se compone de un área de código de 3 dígitos entre paréntesis, un espacio, los tres primeros dígitos del número, un espacio o guión (-), y cuatro dígitos más',
      stitle: 'Hemos recibido tu información',
      stext: '¡Muchas gracias! En breve estaremos en contacto',
      tour1:'AKALI',
      tour2:'TOUR EN BICICLETA ELÉCTRICA Y TACO',
      tour3:'TIROLESA DEL RÍO CANOPY',
      tour4:'NADO CON DELFINES EN AQUAVENTURAS',
      tour5:'BAHÍA ALEGRE MARIETAS',
      tour6:'BARCO PIRATA MARIGALANTE',
      tour7:'RITMOS DE LA NOCHE',
      tour8:'TOUR MAR, SOL, TIERRA TEQUILA & HIKING',
      tour9:'AVISTAMIENTO DE BALLENAS',
      tour10:'HORSE BACK RIDING',
      tour11:'FISHING',
      tour12:'TEATRO VALLARTA MAYAHUEL',
      tour13:'JET SKIES TB TOURS',
      tour14:'UNIQUE ATVs & RZRs',
      tour15:'PLAYA ESCONDIDA EN ISLAS MARIETAS',
      tour16:'CLASES DE COCINA',
      tour17:'BUCEO',
      // country1:'Alemania',
      country1: 'Alemania',
      country2:'Argentina',
      country3	:		'Australia'		,
      country4	:		'Austria'		,
      country5	:		'Bélgica'		,
      country6	:		'Belice'		,
      country7	:		'Bolivia'		,
      country8	:		'Brasil'		,
      country9	:		'Bulgaria'		,
      country10	:		'Canadá'		,
      country11	:		'Chile'		,
      country12	:		'China'		,
      country13	:		'Colombia'		,
      country14	:		'Costa rica'		,
      country15	:		'Croacia'		,
      country16	:		'Cuba'		,
      country17	:		'Dinamarca'		,
      country18	:		'Ecuador'		,
      country19	:		'Egipto'		,
      country20	:		'El Salvador'		,
      country21	:		'Eslovenia'		,
      country22	:		'España'		,
      country23	:		'Estados Unidos'		,
      country24	:		'Filipinas'		,
      country25	:		'Finlandia'		,
      country26	:		'Francia'		,
      country27	:		'Ghana'		,
      country28	:		'Granada'		,
      country29	:		'Grecia'		,
      country30	:		'Guatemala'		,
      country31	:		'Haití'		,
      country32	:		'Honduras'		,
      country33	:		'Hungría'		,
      country34	:		'India'		,
      country35	:		'Indonesia'		,
      country36	:		'Italia'		,
      country37	:		'Jamaica'		,
      country38	:		'Japón'		,
      country39	:		'Malasia'		,
      country40	:		'Marruecos'		,
      country41	:		'México'		,
      country42	:		'Nicaragua'		,
      country43	:		'Nigeria'		,
      country44	:		'Noruega'		,
      country45	:		'Países Bajos'		,
      country46	:		'Pakistán'		,
      country47	:		'Panamá'		,
      country48	:		'Paraguay'		,
      country49	:		'Perú'		,
      country50	:		'Polonia'		,
      country51	:		'Portugal'		,
      country52	:		'Reino Unido'		,
      country53	:		'Rep. Checa'		,
      country54	:		'Rep. de Corea'		,
      country55	:		'República Dominicana'		,
      country56	:		'República Eslovaca'		,
      country57	:		'Rumanía'		,
      country58	:		'Rusia'		,
      country59	:		'Senegal'		,
      country60	:		'Serbia'		,
      country61	:		'Sudáfrica'		,
      country62	:		'Suecia'		,
      country63	:		'Suiza'		,
      country64	:		'Trinidad y Tobago'		,
      country65	:		'Túnez'		,
      country66	:		'Turquía'		,
      country67	:		'Ucrania'		,
      country68	:		'Uruguay'		,
      country69	:		'Venezuela',
    }
  }
  const date = new Date()
  const year = date.getFullYear()
  let month = date.getUTCMonth() + 1
  let day = date.getDay() - 1
  
  


  // Funciones
  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }
  
  useEffect(() => {

    const formatPhone = document.getElementById('formatPhone');

    formatPhone.addEventListener('keyup', e => {
      let value = e.target.value;
      
      //    e.target.value = value.replace(/\W/gi, '').replace(/(.{3})/g, '$1 ').replace(/\s$/,'');
      e.target.value = value.replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    
      })
    
    
    if(getLang() === 'es') {
    
      setLang('es')
    } else {
      setLang('en')
    }
    
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    setToday(`${year}-${month}-${day}`)
    // console.log(`${year}-${month}-${day}`)
  }, [])


  //Se copio el codigo de funcion enviarEmail - pruebas
  /* emailjs.sendForm('service_caps5vd','template_e4mpfym',e.target,'J4Ho6xcH412V-OYi8').then(res=>{
        console.log(res);
      })
  }
  //HB
  emailjs.sendForm('service_zc7yr5j','template_lv2979o',e.target,'qzD2TAtkWYHqu6lvM').then(res=>{

    //Testing
     emailjs.sendForm('service_caps5vd','template_0qrayqj',e.target,'J4Ho6xcH412V-OYi8').then(res=>{
  */

  function enviarEmail(e){
    e.preventDefault();

    emailjs.sendForm('service_zc7yr5j','template_lv2979o',e.target,'qzD2TAtkWYHqu6lvM').then(res=>{
        console.log(res);

        const AmagiLoader = {
          __loader: null,
          show: function () {
      
              if (this.__loader == null) {
                  var divContainer = document.createElement('div');
                  divContainer.style.position = 'fixed';
                  divContainer.style.left = '0';
                  divContainer.style.top = '0';
                  divContainer.style.width = '100%';
                  divContainer.style.height = '100%';
                  divContainer.style.zIndex = '9998';
                  divContainer.style.backgroundColor = 'rgba(250, 250, 250, 0.80)';
      
                  var div = document.createElement('div');
                  div.style.position = 'absolute';
                  div.style.left = '50%';
                  div.style.top = '50%';
                  div.style.zIndex = '9999';
                  div.style.height = '64px';
                  div.style.width = '64px';
                  div.style.margin = '-76px 0 0 -76px';
                  div.style.border = '8px solid #e1e1e1';
                  div.style.borderRadius = '50%';
                  div.style.borderTop = '8px solid #0c4a6e';
                  div.animate([
                      { transform: 'rotate(0deg)' },
                      { transform: 'rotate(360deg)' }
                    ], {
                      duration: 2000,
                      iterations: Infinity
                    });
                  divContainer.appendChild(div);
                  this.__loader = divContainer
                  document.body.appendChild(this.__loader);
              }
              this.__loader.style.display="";
          },
          hide: function(){
              if(this.__loader!=null)
              {
                  this.__loader.style.display="none";
              }
          }
      }

      

      if(getLang() === 'es') {
        swal({
        
          title: "Hemos recibido tu información",
          text: "¡Muchas gracias! En breve estaremos en contacto",
          icon: "success",
          button: "Aceptar",
          timer: "5000",
      });
      } else {
        swal({
        
          title: "We received your information.",
          text: "Thank you very much! We will be in touch soon.",
          icon: "success",
          button: "Accept",
          timer: "5000",
      });
      }

      AmagiLoader.show();
      setTimeout(() => {
          AmagiLoader.hide();
          
          
        window.location = "https://www.hbtours.com.mx/"
      }, 6000);
      
      })
  }

  // Se acabó el código


  return (
    <Layout 
      pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }
    >
      <div className='flex items-center'>
        <div className="section">
          <div className="rounded shadow border mt-24 lg:mt-28 p-4">
            <h2 className='font-bold text-2xl mb-8'>
              { lang === 'es' ? txt.es.formTitle : txt.en.formTitle }
            </h2>

            <form onSubmit={enviarEmail}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  {/* Nombre */}
                  <input 
                    type="text" 
                    name="name" 
                    id="name" 
                    className='form-input'
                    placeholder={ lang === 'es' ? txt.es.formName : txt.en.formName }
                    required
                  />

                  {/* Email */}
                  <input 
                    type="email" 
                    name="email" 
                    id="email" 
                    className='form-input'
                    placeholder={ lang === 'es' ? txt.es.formEmail : txt.en.formEmail }
                    required
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                    {/* Teléfono */}
                    {/*<input min="1" pattern="\([0-9]{3}\) [0-9]{3}[ -][0-9]{4}" */}
                    <input min="1" pattern="^[0-9]{14}+" maxlength="14"
                      type="text" 
                      name="phone" 
                      id="formatPhone" 
                      className='form-input'
                      placeholder={ lang === 'es' ? txt.es.formPhone : txt.en.formPhone }
                    
                      required
                    />

                    {/* País */}
                    <select 
                      name="country" 
                      id="country"
                      className='form-input'
                      placeholder={ lang === 'es' ? txt.es.formCountry : txt.en.formCountry }
                      required
                    >
                      <option selected disabled>
                        { lang === 'es' ? txt.es.formCountry : txt.en.formCountry }
                      </option>
                      <option value={ lang === 'es' ? txt.es.country1 : txt.en.country1 }>{ lang === 'es' ? txt.es.country1 : txt.en.country1 }</option>
                      <option value={ lang === 'es' ? txt.es.country2 : txt.en.country2 }>{ lang === 'es' ? txt.es.country2 : txt.en.country2 }</option>
                      <option value={ lang === 'es' ? txt.es.country3 : txt.en.country3 }>{ lang === 'es' ? txt.es.country3 : txt.en.country3 }</option>
                      <option value={ lang === 'es' ? txt.es.country4 : txt.en.country4 }>{ lang === 'es' ? txt.es.country4 : txt.en.country4 }</option>
                      <option value={ lang === 'es' ? txt.es.country5 : txt.en.country5 }>{ lang === 'es' ? txt.es.country5 : txt.en.country5 }</option>
                      <option value={ lang === 'es' ? txt.es.country6 : txt.en.country6 }>{ lang === 'es' ? txt.es.country6 : txt.en.country6 }</option>
                      <option value={ lang === 'es' ? txt.es.country7 : txt.en.country7 }>{ lang === 'es' ? txt.es.country7 : txt.en.country7 }</option>
                      <option value={ lang === 'es' ? txt.es.country8 : txt.en.country8 }>{ lang === 'es' ? txt.es.country8 : txt.en.country8 }</option>
                      <option value={ lang === 'es' ? txt.es.country9 : txt.en.country9 }>{ lang === 'es' ? txt.es.country9 : txt.en.country9 }</option>
                      <option value={ lang === 'es' ? txt.es.country10 : txt.en.country10 }>{ lang === 'es' ? txt.es.country10 : txt.en.country10 }</option>
                      
                      <option value={ lang === 'es' ? txt.es.country11 : txt.en.country11 }>{ lang === 'es' ? txt.es.country11 : txt.en.country11 }</option>
                      <option value={ lang === 'es' ? txt.es.country12 : txt.en.country12 }>{ lang === 'es' ? txt.es.country12 : txt.en.country12 }</option>
                      <option value={ lang === 'es' ? txt.es.country13 : txt.en.country13 }>{ lang === 'es' ? txt.es.country13 : txt.en.country13 }</option>
                      <option value={ lang === 'es' ? txt.es.country14 : txt.en.country14 }>{ lang === 'es' ? txt.es.country14 : txt.en.country14 }</option>
                      <option value={ lang === 'es' ? txt.es.country15 : txt.en.country15 }>{ lang === 'es' ? txt.es.country15 : txt.en.country15 }</option>
                      <option value={ lang === 'es' ? txt.es.country16 : txt.en.country16 }>{ lang === 'es' ? txt.es.country16 : txt.en.country16 }</option>
                      <option value={ lang === 'es' ? txt.es.country17 : txt.en.country17 }>{ lang === 'es' ? txt.es.country17 : txt.en.country17 }</option>
                      <option value={ lang === 'es' ? txt.es.country18 : txt.en.country18 }>{ lang === 'es' ? txt.es.country18 : txt.en.country18 }</option>
                      <option value={ lang === 'es' ? txt.es.country19 : txt.en.country19 }>{ lang === 'es' ? txt.es.country19 : txt.en.country19 }</option>
                      <option value={ lang === 'es' ? txt.es.country20 : txt.en.country20 }>{ lang === 'es' ? txt.es.country20 : txt.en.country20 }</option>

                      <option value={ lang === 'es' ? txt.es.country21 : txt.en.country21 }>{ lang === 'es' ? txt.es.country21 : txt.en.country21 }</option>
                      <option value={ lang === 'es' ? txt.es.country22 : txt.en.country22 }>{ lang === 'es' ? txt.es.country22 : txt.en.country22 }</option>
                      <option value={ lang === 'es' ? txt.es.country23 : txt.en.country23 }>{ lang === 'es' ? txt.es.country23 : txt.en.country23 }</option>
                      <option value={ lang === 'es' ? txt.es.country24 : txt.en.country24 }>{ lang === 'es' ? txt.es.country24 : txt.en.country24 }</option>
                      <option value={ lang === 'es' ? txt.es.country25 : txt.en.country25 }>{ lang === 'es' ? txt.es.country25 : txt.en.country25 }</option>
                      <option value={ lang === 'es' ? txt.es.country26 : txt.en.country26 }>{ lang === 'es' ? txt.es.country26 : txt.en.country26 }</option>
                      <option value={ lang === 'es' ? txt.es.country27 : txt.en.country27 }>{ lang === 'es' ? txt.es.country27 : txt.en.country27 }</option>
                      <option value={ lang === 'es' ? txt.es.country28 : txt.en.country28 }>{ lang === 'es' ? txt.es.country28 : txt.en.country28 }</option>
                      <option value={ lang === 'es' ? txt.es.country29 : txt.en.country29 }>{ lang === 'es' ? txt.es.country29 : txt.en.country29 }</option>
                      <option value={ lang === 'es' ? txt.es.country30 : txt.en.country30 }>{ lang === 'es' ? txt.es.country30 : txt.en.country30 }</option>

                      <option value={ lang === 'es' ? txt.es.country31 : txt.en.country31 }>{ lang === 'es' ? txt.es.country31 : txt.en.country31 }</option>
                      <option value={ lang === 'es' ? txt.es.country32 : txt.en.country32 }>{ lang === 'es' ? txt.es.country32 : txt.en.country32 }</option>
                      <option value={ lang === 'es' ? txt.es.country33 : txt.en.country33 }>{ lang === 'es' ? txt.es.country33 : txt.en.country33 }</option>
                      <option value={ lang === 'es' ? txt.es.country34 : txt.en.country34 }>{ lang === 'es' ? txt.es.country34 : txt.en.country34 }</option>
                      <option value={ lang === 'es' ? txt.es.country35 : txt.en.country35 }>{ lang === 'es' ? txt.es.country35 : txt.en.country35 }</option>
                      <option value={ lang === 'es' ? txt.es.country36 : txt.en.country36 }>{ lang === 'es' ? txt.es.country36 : txt.en.country36 }</option>
                      <option value={ lang === 'es' ? txt.es.country37 : txt.en.country37 }>{ lang === 'es' ? txt.es.country37 : txt.en.country37 }</option>
                      <option value={ lang === 'es' ? txt.es.country38 : txt.en.country38 }>{ lang === 'es' ? txt.es.country38 : txt.en.country38 }</option>
                      <option value={ lang === 'es' ? txt.es.country39 : txt.en.country39 }>{ lang === 'es' ? txt.es.country39 : txt.en.country39 }</option>
                      <option value={ lang === 'es' ? txt.es.country40 : txt.en.country40 }>{ lang === 'es' ? txt.es.country40 : txt.en.country40 }</option>

                      <option value={ lang === 'es' ? txt.es.country41 : txt.en.country41 }>{ lang === 'es' ? txt.es.country41 : txt.en.country41 }</option>
                      <option value={ lang === 'es' ? txt.es.country42 : txt.en.country42 }>{ lang === 'es' ? txt.es.country42 : txt.en.country42 }</option>
                      <option value={ lang === 'es' ? txt.es.country43 : txt.en.country43 }>{ lang === 'es' ? txt.es.country43 : txt.en.country43 }</option>
                      <option value={ lang === 'es' ? txt.es.country44 : txt.en.country44 }>{ lang === 'es' ? txt.es.country44 : txt.en.country44 }</option>
                      <option value={ lang === 'es' ? txt.es.country45 : txt.en.country45 }>{ lang === 'es' ? txt.es.country45 : txt.en.country45 }</option>
                      <option value={ lang === 'es' ? txt.es.country46 : txt.en.country46 }>{ lang === 'es' ? txt.es.country46 : txt.en.country46 }</option>
                      <option value={ lang === 'es' ? txt.es.country47 : txt.en.country47 }>{ lang === 'es' ? txt.es.country47 : txt.en.country47 }</option>
                      <option value={ lang === 'es' ? txt.es.country48 : txt.en.country48 }>{ lang === 'es' ? txt.es.country48 : txt.en.country48 }</option>
                      <option value={ lang === 'es' ? txt.es.country49 : txt.en.country49 }>{ lang === 'es' ? txt.es.country49 : txt.en.country49 }</option>
                      <option value={ lang === 'es' ? txt.es.country50 : txt.en.country50 }>{ lang === 'es' ? txt.es.country50 : txt.en.country50 }</option>

                      <option value={ lang === 'es' ? txt.es.country51 : txt.en.country51 }>{ lang === 'es' ? txt.es.country51 : txt.en.country51 }</option>
                      <option value={ lang === 'es' ? txt.es.country52 : txt.en.country52 }>{ lang === 'es' ? txt.es.country52 : txt.en.country52 }</option>
                      <option value={ lang === 'es' ? txt.es.country53 : txt.en.country53 }>{ lang === 'es' ? txt.es.country53 : txt.en.country53 }</option>
                      <option value={ lang === 'es' ? txt.es.country54 : txt.en.country54 }>{ lang === 'es' ? txt.es.country54 : txt.en.country54 }</option>
                      <option value={ lang === 'es' ? txt.es.country55 : txt.en.country55 }>{ lang === 'es' ? txt.es.country55 : txt.en.country55 }</option>
                      <option value={ lang === 'es' ? txt.es.country56 : txt.en.country56 }>{ lang === 'es' ? txt.es.country56 : txt.en.country56 }</option>
                      <option value={ lang === 'es' ? txt.es.country57 : txt.en.country57 }>{ lang === 'es' ? txt.es.country57 : txt.en.country57 }</option>
                      <option value={ lang === 'es' ? txt.es.country58 : txt.en.country58 }>{ lang === 'es' ? txt.es.country58 : txt.en.country58 }</option>
                      <option value={ lang === 'es' ? txt.es.country59 : txt.en.country59 }>{ lang === 'es' ? txt.es.country59 : txt.en.country59 }</option>
                      <option value={ lang === 'es' ? txt.es.country60 : txt.en.country60 }>{ lang === 'es' ? txt.es.country60 : txt.en.country60 }</option>

                      <option value={ lang === 'es' ? txt.es.country61 : txt.en.country61 }>{ lang === 'es' ? txt.es.country61 : txt.en.country61 }</option>
                      <option value={ lang === 'es' ? txt.es.country62 : txt.en.country62 }>{ lang === 'es' ? txt.es.country62 : txt.en.country62 }</option>
                      <option value={ lang === 'es' ? txt.es.country63 : txt.en.country63 }>{ lang === 'es' ? txt.es.country63 : txt.en.country63 }</option>
                      <option value={ lang === 'es' ? txt.es.country64 : txt.en.country64 }>{ lang === 'es' ? txt.es.country64 : txt.en.country64 }</option>
                      <option value={ lang === 'es' ? txt.es.country65 : txt.en.country65 }>{ lang === 'es' ? txt.es.country65 : txt.en.country65 }</option>
                      <option value={ lang === 'es' ? txt.es.country66 : txt.en.country66 }>{ lang === 'es' ? txt.es.country66 : txt.en.country66 }</option>
                      <option value={ lang === 'es' ? txt.es.country67 : txt.en.country67 }>{ lang === 'es' ? txt.es.country67 : txt.en.country67 }</option>
                      <option value={ lang === 'es' ? txt.es.country68 : txt.en.country68 }>{ lang === 'es' ? txt.es.country68 : txt.en.country68 }</option>
                      <option value={ lang === 'es' ? txt.es.country69 : txt.en.country69 }>{ lang === 'es' ? txt.es.country69 : txt.en.country69 }</option>
                      
                      
                      {/* <option value="EE.UU.">EE.UU.</option>
                      <option value="México">México</option> */}
                      {/* <datalist id="country" name="country">
                        <option value={ lang === 'es' ? txt.es.country1 : txt.en.country1 }></option>
                        <option value={ lang === 'es' ? txt.es.country2 : txt.en.country2 }></option>
                        <option value={ lang === 'es' ? txt.es.country3 : txt.en.country3 }></option>
                        <option value={ lang === 'es' ? txt.es.country4 : txt.en.country4 }></option>
                        <option value={ lang === 'es' ? txt.es.country5 : txt.en.country5 }></option>
                        <option value={ lang === 'es' ? txt.es.country6 : txt.en.country6 }></option>
                        <option value={ lang === 'es' ? txt.es.country7 : txt.en.country7 }></option>
                        <option value={ lang === 'es' ? txt.es.country8 : txt.en.country8 }></option>
                        <option value={ lang === 'es' ? txt.es.country9 : txt.en.country9 }></option>
                      </datalist> */}
                    </select>

                    {/* Ciudad */}
                    <input 
                      type="text" 
                      name="city" 
                      id="city" 
                      className='form-input'
                      placeholder={ lang === 'es' ? txt.es.formCity : txt.en.formCity }
                      required
                    />

                    {/* CP */}
                    <input min="1" pattern="^[0-9]+"
                      type="number" 
                      name="zipcode" 
                      id="zipcode" 
                      className='form-input'
                      placeholder={ lang === 'es' ? txt.es.formZip : txt.en.formZip }
                      required
                    />
                  </div>
                </div>
                <div>
                    {/* Tour */}
                    <input 
                      list="tour" 
                      placeholder={ lang === 'es' ? txt.es.formTour : txt.en.formTour }
                      className="form-input"
                      name="tour"
                      
                    />
                    <datalist id="tour" name="tour">
                        <option value={ lang === 'es' ? txt.es.tour1 : txt.en.tour1 }></option>
                        <option value={ lang === 'es' ? txt.es.tour2 : txt.en.tour2 }></option>
                        <option value={ lang === 'es' ? txt.es.tour3 : txt.en.tour3 }></option>
                        <option value={ lang === 'es' ? txt.es.tour4 : txt.en.tour4 }></option>
                        <option value={ lang === 'es' ? txt.es.tour5 : txt.en.tour5 }></option>
                        <option value={ lang === 'es' ? txt.es.tour6 : txt.en.tour6 }></option>
                        <option value={ lang === 'es' ? txt.es.tour7 : txt.en.tour7 }></option>
                        <option value={ lang === 'es' ? txt.es.tour8 : txt.en.tour8 }></option>
                        <option value={ lang === 'es' ? txt.es.tour9 : txt.en.tour9 }></option>
                        <option value={ lang === 'es' ? txt.es.tour10 : txt.en.tour10 }></option>
                        <option value={ lang === 'es' ? txt.es.tour11 : txt.en.tour11 }></option>
                        <option value={ lang === 'es' ? txt.es.tour12 : txt.en.tour12 }></option>
                        <option value={ lang === 'es' ? txt.es.tour13 : txt.en.tour13 }></option>
                        <option value={ lang === 'es' ? txt.es.tour14 : txt.en.tour14 }></option>
                        <option value={ lang === 'es' ? txt.es.tour15 : txt.en.tour15 }></option>
                        <option value={ lang === 'es' ? txt.es.tour16 : txt.en.tour16 }></option>
                        <option value={ lang === 'es' ? txt.es.tour17 : txt.en.tour17 }></option>
                    </datalist>

                    {/* <select 
                    
                      name="tour" 
                      id="tour" 
                      className="form-input"
                      //onchange= "InputOculto(this.value);" 
                      

                      placeholder={ lang === 'es' ? txt.es.formTour : txt.en.formTour }
                      //onchange= "if(this.value=="Otro") {document.getElementById("otrocolor").disabled = false} else {document.getElementById("otrocolor").disabled = true}"
                    >
                      <option selected disabled required>
                        { lang === 'es' ? txt.es.formTour : txt.en.formTour }
                      </option>
                      <option value="akali">AKALI / AKALI</option>
                      <option value="bike">TOUR EN BICICLETA ELÉCTRICA Y TACO / E BIKE & TACO TOUR</option>
                      <option value="canopy">TIROLESA DEL RÍO CANOPY / CANOPY RIVER ZIP LINE</option>
                      <option value="dolphins">NADO CON DELFINES EN AQUAVENTURAS / SWIM WITH DOLPHINS AT AQUAVENTURAS</option>
                      <option value="marietas">BAHÍA ALEGRE MARIETAS / BAHÍA ALEGRE MARIETAS</option>
                      <option value="pirate">BARCO PIRATA MARIGALANTE / MARIGALANTE PIRATE SHIP</option>
                      <option value="night">RITMOS DE LA NOCHE / RHYTHMS OF THE NIGHT</option>
                      <option value="tequila">TOUR MAR, SOL, TIERRA TEQUILA & HIKING / SEA, SUN, LAND TEQUILA & HIKING TOUR</option>
                      <option value="whales">AVISTAMIENTO DE BALLENAS / WHALES WATCHING</option>
                      
                    </select> */}
                      {/*
                      <input id="tourInput" type="text"  />
                      <input type="text" id="otrocolor" name="color" disabled/>
                      */}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4"> 
                    
                    {/* Fecha */}
                    <input 
                      type="date" 
                      name="date" 
                      id="date" 
                      className="form-input"
                      min={today}
                      placeholder={ lang === 'es' ? txt.es.formDate : txt.en.formDate }
                      required
                    />

                    {/* No. de Personas */}
                    <input min="1" max="100" pattern="^[0-9]+"
                      type="number" 
                      name="persons" 
                      id="persons" 
                      className="form-input" 
                      placeholder={ lang === 'es' ? txt.es.formNoPer : txt.en.formNoPer }
                      required
                    />
                  </div>

                  {/* Mensaje */}
                  <textarea 
                    name="message" 
                    id="message" 
                    cols="30" 
                    rows="10" 
                    className="form-input"
                    placeholder={ lang === 'es' ? txt.es.formMsg : txt.en.formMsg }
                  />

                  <div className="text-right">
                    <button className='bg-sky-800 hover:bg-sky-900 text-xl text-neutral-50 px-8 py-2 rounded-full' type="submit">
                      { lang === 'es' ? txt.es.formBtn : txt.en.formBtn }
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
 
export default Contacto;